import { createRouter, createWebHistory } from 'vue-router'
import store from "../store";
import middlewarePipeline from "./middlewarePipeline";
import authMiddleware from "./middleware/auth";
import adminRoutes from './zones/admin'
import flowRoutes from './zones/flow'
import authRoutes from './zones/auth'
import websiteRoutes from './zones/website'
import usersRoutes from './zones/users'
import secondScreenRoutes from './zones/secondScreen'
import { events } from '../utils/eventBus';

const routes = [
    {
        path: "/",
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/AppLayout.vue"),
        children: [
            {
                path: "",
                name: "dashboard",
                meta: {
                    title: () => 'Dashboard',
                    middleware: [authMiddleware]
                },
                component: () => import(/* webpackChunkName: "dashboard" */ "@/views/base/Dashboard.vue"),
            },
            ...usersRoutes,
            ...websiteRoutes,
            ...adminRoutes,
            ...flowRoutes,
            {
                path: '/:unknownPath(.*)*', name: 'NotFound',
                meta: { title:()=>'Whoopsie',middleware: [authMiddleware] },
                component: () => import(/* webpackChunkName: "404" */ "@/views/base/NotFound.vue")
            }
        ]
    },
    ...authRoutes,
    ...secondScreenRoutes,
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    const middleware = to.meta.middleware;
    const context = { to, from, next, store };
    
    // Carry on if no middleware is present
    if (!middleware) {
        return next();
    }

    // Run middleware(s)
    middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
    });
});
router.afterEach((to, from, failure) => {
    if(sessionStorage.getItem(events.NEW_VERSION_AVAILABLE) === "yes"){
        sessionStorage.setItem(events.NEW_VERSION_AVAILABLE, "no")
        router.go()
    }
    if (to.meta.hasOwnProperty('title') && typeof to.meta.title == typeof Function)
        document.title = to.meta.title()
    else
        document.title = 'Quattro'
})
export default router;