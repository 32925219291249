import mitt from 'mitt'
import {debounce} from '@vratier/super-vue-ui'

const emitter = mitt()
const eventBus = {
    on: emitter.on,
    off: emitter.off,
    emit: (a,b,c)=>{
        if (import.meta.env.DEV && import.meta.env.VITE_LOG_EVENTS == "yes") {
          console.log(a)
        }
        emitter.emit(a,b ,c)
    }
}
export const events = {
    NEW_VERSION_AVAILABLE: 'newVersionAvailable',
    THEME_UPDATED: 'themeUpdated',
    ENDPOINT_TREE_UPDATED: 'endpointTreeUpdated',
    CONTENT_TREE_UPDATED: 'contentTreeUpdated',
    PREVIEWED_CONTENT_CHANGED: 'previewedContentChanged',
    LAYOUT_CHANGED: 'layoutChanged'
}
const useEventBus = ()=> {
    return eventBus
}
window.addEventListener('resize', debounce(null, ()=>{
    eventBus.emit(events.LAYOUT_CHANGED)
}, 250));

export {useEventBus}