import Factory from "@/scripts/services/Factory";
export function createEditorStoreModule(mainEditor) {
    function buildValidationErrorMessage(errors: any) {
        let msg = "";
        for (const key in errors) {
            if (Object.prototype.hasOwnProperty.call(errors, key)) {
                errors[key].map((e) => {
                    msg += `<li>${e}</li>`;
                });
            }
        }
        return msg
            ? `<div class="m-3 prose prose-sm"><p>Please fix the following errors and try again:</p><ul>${msg}</ul></div>`
            : "Unknown error";
    }
    return {
        namespaced: true,
        state: () => ({
            editedObject: null,
            dirty: false,
            loading: false,
            saving: false,
            errorMessage: null,
            validationErrors: {},
        }),
        getters: {
            objectId(state) {
                return state.editedObject ? state.editedObject.id : null;
            },
        },
        mutations: {
            setErrorMessage(state, errMess) {
                state.errorMessage = errMess;
            },
            setValidationErrors(state, valErrs) {
                state.validationErrors = valErrs;
            },
            setDirty(state, d) {
                state.dirty = d;
            },
            startLoading(state) {
                state.loading = true;
            },
            stopLoading(state) {
                state.loading = false;
            },
            startSaving(state) {
                state.saving = true;
            },
            stopSaving(state) {
                state.saving = false;
            },
            setObject(state, o) {
                state.editedObject = o;
            },
            updateObject(state, { key, value }) {
                state.editedObject[key] = value;
            },
        },
        actions: {
            clearErrors({ commit }) {
                commit("setValidationErrors", {});
                commit("setErrorMessage", null);
            },
            handleErrors({ commit, state }, e) {
                console.error(e);
                console.error(JSON.parse(JSON.stringify(e)));
                let title = "Quattro Error";
                let msg = e.message || "Unknown error";
                if (e.response) {
                    switch (e.response.status) {
                        case 422:
                            commit(
                                "setValidationErrors",
                                e.response.data.errors
                            );
                            title = "Invalid data";
                            msg = buildValidationErrorMessage(
                                e.response.data.errors
                            );
                            break;
                        case 404:
                            title = "Item not found";
                            msg =
                                "The requested item cannot be found.<br/>Maybe this object was deleted or the URL is incorrect.<br/>Please make sure you are on the correct page.";
                            break;
                        default:
                            msg += "<br/>" + e.response.data.message;
                            break;
                    }
                }
                commit("setErrorMessage", msg);
                alert(
                    title +
                        "\n\n" +
                        state.errorMessage.replace(/<br ?\/>/, "\n")
                );
                throw e;
            },
            updateObject({ commit }, { key, value }) {
                commit("setDirty", true);
                if (mainEditor) {
                    mainEditor.touch();
                }
                commit("updateObject", { key, value });
            },
            setStatus({ commit }, status) {
                if (status.hasOwnProperty("dirty")) {
                    commit("setDirty", !!status.dirty);
                }
                if (status.hasOwnProperty("loading")) {
                    if (!!status.loading) {
                        commit("startLoading");
                    } else {
                        commit("stopLoading");
                    }
                }
                if (status.hasOwnProperty("saving")) {
                    if (!!status.saving) {
                        commit("startSaving");
                    } else {
                        commit("stopSaving");
                    }
                }
            },
            async addToMany(
                { commit, dispatch, state },
                { object_type, fk, data, parentType, parentIsWebobject }
            ) {
                commit("startLoading");
                try {
                    dispatch("clearErrors");
                    data[fk] = state.editedObject.id;
                    let o = await Factory.save(object_type, data, false);
                    commit("setObject", o);
                    await Factory.load(
                        parentType,
                        state.editedObject.id,
                        parentIsWebobject
                    );
                    return o;
                } catch (error) {
                    dispatch("handleErrors", error);
                } finally {
                    commit("stopLoading");
                }
                return null;
            },
            async createObject(
                { commit, dispatch },
                { object_type, data, isWebContent }
            ) {
                commit("startLoading");
                try {
                    let o = await Factory.createObject(
                        object_type,
                        data,
                        isWebContent
                    );
                    commit("setObject", o);
                    commit("setDirty", false);
                    dispatch("clearErrors");
                    return o;
                } catch (error) {
                    dispatch("handleErrors", error);
                } finally {
                    commit("stopLoading");
                }
            },
            async openUrl({ commit, dispatch }, { url }) {
                // e.g.: /api/me
                commit("startLoading");
                try {
                    let o = await Factory.loadUrl(url);
                    commit("setObject", o);
                    commit("setDirty", false);
                    dispatch("clearErrors");
                    return o;
                } catch (error) {
                    dispatch("handleErrors", error);
                } finally {
                    commit("stopLoading");
                }
            },
            async openObject(
                { commit, dispatch },
                { object_type, id, isWebContent }
            ) {
                commit("startLoading");
                try {
                    let o = await Factory.load(object_type, id, isWebContent);
                    commit("setObject", o);
                    commit("setDirty", false);
                    dispatch("clearErrors");
                    return o;
                } catch (error) {
                    dispatch("handleErrors", error);
                } finally {
                    commit("stopLoading");
                }
            },
            async saveObject(
                { commit, dispatch },
                { object_type, obj, isWebContent }
            ) {
                commit("startSaving");
                try {
                    const o = await Factory.save(
                        object_type,
                        obj,
                        isWebContent
                    );
                    commit("setObject", o);
                    commit("setDirty", false);
                    dispatch("clearErrors");
                    return o;
                } catch (error) {
                    dispatch("handleErrors", error);
                } finally {
                    commit("stopSaving");
                }
            },
        },
    };
}
