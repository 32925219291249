import api from './ApiService';
import { useEventBus, events } from '../utils/eventBus';
export default {
    async loadContentTree() {
        let response = await api.get("/api/web_content/tree");
        return response.data
    },
    async loadTemplateTree() {
        let response = await api.get("/api/web_template/tree");
        return response.data
    },
    async importContent(type, fields, data) {
        let response = await api.post("/api/web_content/import", { type, fields, data});
        return response.data
    },
    async loadTree() {
        let response = await api.get("/api/endpoints");
        return response.data
    },
    async loadTheme() {
        let response = await api.get("/api/themes/website");
        return response.data
    },
    async loadPreviewObjectURL(params) {
        let response = await api.get("/api/website/preview", {params});
        return URL.createObjectURL(new Blob([response.data], { type: 'text/html' }))
    },
    async publishEndpointVersion(version_id, publish_from, publish_until) {
        let response =  await api.post(`/api/endpoint-versions/${version_id}/publish`, {publish_from,publish_until});
        return response.data
    },
    async deleteWebsiteContent(type, obj) {
        let response = await api.del(`/api/website-contents/${type}/${obj.id}`,{updated_at:obj.updated_at});
        useEventBus().emit(events.CONTENT_TREE_UPDATED)
        return response.data
    },
    async deleteEndpoint(obj) {
        let response = await api.del(`/api/endpoints/${obj.id}`,{updated_at:obj.updated_at});
        useEventBus().emit(events.ENDPOINT_TREE_UPDATED)
        return response.data
    },
    async loadEndpointSlugs(endpoint_id) {
        let response = await api.get(`/api/endpoints/${endpoint_id}/slugs`);
        return {endpoint_id:endpoint_id, slugs: response.data}
    },
}
