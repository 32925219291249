<template>
  <router-link class="flex flex-row" :to="to">
    <div class="inline-flex justify-center items-center rounded-full w-6 h-6 bg-brand text-brand-contrast">
      <SIcon :icon="icon"></SIcon>
    </div>
    <div v-if="txt" class="ml-3" v-text="txt"/>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: 'IconLink',
  props: {
    icon: {
      type: String,
      required: true
    },
    to: null,
    txt: null
  },
  setup() {

    return {}
  }
})
</script>