<template>
  <div class="overflow-hidden relative" :class="isSub ?'': 'h-full'" :data-sub="isSub?'yes':'no'">
    <div class="absolute inset-0 bg-white bg-opacity-60 z-20 flex justify-center items-center text-2xl" v-if="!isSub && loading">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20 animate-spin-reverse" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
      </svg>
    </div>
    <div class="grid" :class="[!isSub && 'grid-rows-toolbar h-full overflow-auto']">
      <div class="p-1 flex bg-gray-50" v-if="!isSub">
        
          <IconLink v-if="editedObject && backTo && backTo(editedObject)" class="my-auto mr-3" :txt="cbl(editedObject)" icon="chevron-up" :to="backTo(editedObject)" />
          <div class="my-auto pl-3" :class="backTo && 'border-l'">
            <div class="text-lg text-brand" v-text="title(editedObject)"></div>
          </div>
          <div class="flex-grow"></div>
          <slot name="buttons"  :editedObject="editedObject" :setStatus="setStatus" :dirty="dirty" :saving="saving" :save="save" :cancel="cancel" :errors="validationErrors"></slot>
          <div class="flex-grow"></div>
          <SButton class="" :disabled="!dirty" :loading="saving" @click="save">Save</SButton>
          <SButton class="ml-2" :disabled="!dirty || saving" @click="cancel">Cancel</SButton>
        
      </div>
      <div class="grid grid-cols-sidebar-end h-full">
        <div class="overflow-auto relative">
          <slot :editedObject="editedObject" :editor="editor" :dirty="dirty" :loading="loading" :saving="saving" :errors="validationErrors" />
        </div>
        <div class="shadow-xl border-l relative" v-if="$slots.drawer">
          <div class="absolute top-0 border-l border-b p-2 z-1" style="right:100%;">&lt;</div>
          <div class="overflow-auto">
            <slot name="drawer" :editedObject="editedObject" :dirty="dirty" :loading="loading" :saving="saving" :errors="validationErrors" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { capitalize, computed, defineComponent, ref, watch } from "vue";
import { createEditor } from "@/scripts/store/Editor";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import IconLink from "@/views/components/IconLink.vue";
export default defineComponent({
  components: { IconLink },
  props: {
    type: null,
    notFound: null,
    reloadOn: null,
    routeKey: null,
    routeActions: null,
    mainEditor: null,
    saveHandler: null,
    loadedHandler: null,
    beforeSaveHandler: { type: Function, default: null },
    backTo: null,
    options: {
      type: Object,
      default: () => { return {} }
    }
  },
  async setup(props) {
    const params = Object.assign({ titleKey: 'name' }, props.options)
    const editedObject = ref({})
    const objectLoaded = ()=>{
      editedObject.value = editor.mapObject()
      if(props.loadedHandler){
        props.loadedHandler(editor, editedObject.value)
      }
    }
    let editor = createEditor(useStore(), useRouter(), props.type, props.routeKey, props.routeActions, props.saveHandler, props.beforeSaveHandler, props.options.isWebContent, objectLoaded, props.notFound, props.reloadOn, props.mainEditor)
    await editor.handleRouteChange(useRoute())
    const cleanType = props.type.replace(/[-_]/g, ' ').replace(/s$/, '')
    const title = (o) => {
      if (o.id) {
        return capitalize(cleanType) + (o[params.titleKey] ? ': ' + o[params.titleKey] : '')
      } else {
        return "New " + cleanType + (o[params.titleKey] ? ': ' + o[params.titleKey] : '')
      }
    }
    return {
      ...editor.status,
      isSub: editor.isSub,
      setStatus: editor.setStatus,
      save: editor.save,
      cancel: editor.cancel,
      editedObject,editor,
      cbl: computed(() => { return !params.backLabel ? () => null : params.backLabel }),
      title
    }
  }
})
</script>

<style>
</style>