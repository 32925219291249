<template>
    <div class="max-w-screen-sm">
        <div class="grid grid-cols-1 gap-6 p-3">
            <SField :label="label" :info="info">
                <STextInput :type="inputType" autocomplete="off" v-model="value" />
            </SField>
        </div>
    </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
export default defineComponent({
    name:'SimplePromptDialog',
    props:{
        label:{type:String,default:''},
        info:{type:String,default:''},
        inputType:{type:String,default:'text'}
    },
    async setup(props) {
        return {
            value: ref(''),
        };
    }
})
</script>