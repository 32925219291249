export default function auth({ to, next, store }) {
    const loginQuery = { path: "/login", query: { redirect: to.fullPath } };
    if (!store.getters["auth/me"]) {
        if (store.getters["auth/guest"]) {
            next(loginQuery)
        } else {
            store.dispatch("auth/loadMe").then(() => {
                if (!store.getters["auth/me"]) next(loginQuery)
                else next()
            })
        }
    } else {
        next()
    }
}