
import { h } from "vue";
import auth from "../middleware/auth";
import createRemeberMiddleware from "../middleware/remember";
import {setSessionSetting, getSessionSetting} from '@vratier/super-vue-ui'
const rememberKey = 'quattro-web-route'
const remember = createRemeberMiddleware(rememberKey)
export default [
    {
        path: "/website",
        name: 'website',
        meta: { middleware: [auth], title(){return 'CMS'} },
        redirect: to => {
            return getSessionSetting(rememberKey,{name:"website-content"})
        },
        // component: () => import(/* webpackChunkName: "website" */ "@/views/website/WebDashboard.vue"),
        // children: [
        //     {
        //         name: 'website',
        //         path: '',
        //         components: {
        //             default: {
        //                 render() {
        //                     return h('div', {}, [h('p', {class:'m-1 p-2 bg-gray-100 rounded'}, '<<< Select something to edit...')])
        //                 }
        //             },
        //             // sidebar: () => import(/* webpackChunkName: "website" */ "@/views/website/components/EndpointTree.vue"),
        //         },
        //     }
        // ]
    },
    {
        path: "/website/urls",
        meta: { middleware: [auth, remember], title(){return 'CMS | URLs'}},
        component: () => import(/* webpackChunkName: "website" */ "@/views/website/WebDashboard.vue"),
        children: [
            {
                name: 'endpoints',
                path: '',
                components: {
                    default: {
                        render() {
                            return h('div', {}, [h('p', {class:'m-1 p-2 bg-gray-100 rounded'}, '<<< Select something to edit...')])
                        }
                    },
                    sidebar: () => import(/* webpackChunkName: "website" */ "@/views/website/components/EndpointTree.vue"),
                },
            },
            {
                name: 'endpoint:create-child',
                path: ':endpoint_id/create-child',
                components: {
                    default: () => import(/* webpackChunkName: "website" */ "@/views/website/EndpointEditor.vue"),
                    sidebar: () => import(/* webpackChunkName: "website" */ "@/views/website/components/EndpointTree.vue"),
                },
            },
            {
                name: 'endpoint',
                path: ':endpoint_id',
                components: {
                    default: () => import(/* webpackChunkName: "website" */ "@/views/website/EndpointEditor.vue"),
                    sidebar: () => import(/* webpackChunkName: "website" */ "@/views/website/components/EndpointTree.vue"),
                },
                children: [
                    {
                        path: "versions/:endpoint_version_id",
                        name: "endpoint-version",
                        meta: { middleware: [auth] },
                        components: {
                        },
                        children: [
                            {
                                path: ":slug",
                                name: "endpoint-version-slug",
                                meta: { middleware: [auth] },
                                components: {
                                }
                            }
                        ]
                    },
                    {
                        path: "versions/create",
                        name: "endpoint-version:create",
                        meta: { middleware: [auth] },
                        components: {
                        }
                    },
                ]
            },
            {
                name: 'version-template',
                path: ':endpoint_id/versions/:endpoint_version_id/:object_type/:object_id',
                components: {
                    default: () => import(/* webpackChunkName: "website" */ "@/views/website/EndpointTemplateEditor.vue"),
                    sidebar: () => import(/* webpackChunkName: "website" */ "@/views/website/components/EndpointTree.vue"),
                },
            }
        ]
    },
    {
        path: "/website/content",
        meta: { middleware: [auth, remember], title(){return 'CMS | Content'}},
        component: () => import(/* webpackChunkName: "website" */ "@/views/website/WebDashboard.vue"),
        children: [
            {
                name: "website-content",
                path: '',
                components: {
                    default: {
                        render() {
                            return h('div', {}, [h('p', { class: 'm-1 p-2 bg-gray-100 rounded' }, '<<< Select something to edit...')])
                        }
                    },
                    sidebar: () => import(/* webpackChunkName: "website" */ "@/views/website/components/ContentTree.vue"),
                },
            },
            {
                name: 'website-content-list',
                path: ':object_type',
                components: {
                    default: () => import(/* webpackChunkName: "website" */ "@/views/website/WebsiteContentList.vue"),
                    sidebar: () => import(/* webpackChunkName: "website" */ "@/views/website/components/ContentTree.vue"),
                },
            },
            {
                name: 'endpoint-ad-hoc-template-list',
                path: ':object_type(ad-hoc)',
                components: {
                    default: () => import(/* webpackChunkName: "website" */ "@/views/website/EndpointTemplateList.vue"),
                    sidebar: () => import(/* webpackChunkName: "website" */ "@/views/website/components/ContentTree.vue"),
                },
            },
            {
                name: 'endpoint-ad-hoc-template-item',
                path: ':object_type(ad-hoc)/:object_id',
                components: {
                    default: () => import(/* webpackChunkName: "website" */ "@/views/website/EndpointTemplateEditor.vue"),
                    sidebar: () => import(/* webpackChunkName: "website" */ "@/views/website/components/ContentTree.vue"),
                },
            },
            {
                name: 'endpoint-ad-hoc-template-create',
                path: ':object_type(ad-hoc)/create',
                components: {
                    default: () => import(/* webpackChunkName: "website" */ "@/views/website/EndpointTemplateEditor.vue"),
                    sidebar: () => import(/* webpackChunkName: "website" */ "@/views/website/components/ContentTree.vue"),
                },
            },
            {
                name: 'website-content-item',
                path: ':object_type/:object_id',
                components: {
                    default: () => import(/* webpackChunkName: "website" */ "@/views/website/WebsiteContentEditor.vue"),
                    sidebar: () => import(/* webpackChunkName: "website" */ "@/views/website/components/ContentTree.vue"),
                },
            },
            {
                name: 'website-content-create',
                path: ':object_type/create',
                components: {
                    default: () => import(/* webpackChunkName: "website" */ "@/views/website/WebsiteContentEditor.vue"),
                    sidebar: () => import(/* webpackChunkName: "website" */ "@/views/website/components/ContentTree.vue"),
                },
            },
            {
                name: 'website-content-import',
                path: ':object_type/import',
                components: {
                    default: () => import(/* webpackChunkName: "website" */ "@/views/website/WebsiteContentImport.vue"),
                    sidebar: () => import(/* webpackChunkName: "website" */ "@/views/website/components/ContentTree.vue"),
                },
            },
        ]
    },
    {
        path: "/website/templates",
        meta: { middleware: [auth, remember], title(){return 'CMS | Templates'}},
        component: () => import(/* webpackChunkName: "website" */ "@/views/website/WebDashboard.vue"),
        children: [
            {
                name: "endpoint-template",
                path: '',
                components: {
                    default: {
                        render() {
                            return h('div', {}, [h('p', { class: 'm-1 p-2 bg-gray-100 rounded' }, '<<< Select something to edit...')])
                        }
                    },
                    sidebar: () => import(/* webpackChunkName: "website" */ "@/views/website/components/EndpointTemplateTree.vue"),
                },
            },
            {
                name: 'endpoint-shared-template-list',
                path: 'shared',
                components: {
                    default: () => import(/* webpackChunkName: "website" */ "@/views/website/EndpointTemplateList.vue"),
                    sidebar: () => import(/* webpackChunkName: "website" */ "@/views/website/components/EndpointTemplateTree.vue"),
                },
            },
            {
                name: 'endpoint-shared-template-item',
                path: 'shared/:object_id',
                components: {
                    default: () => import(/* webpackChunkName: "website" */ "@/views/website/EndpointTemplateEditor.vue"),
                    sidebar: () => import(/* webpackChunkName: "website" */ "@/views/website/components/EndpointTemplateTree.vue"),
                },
            },
            {
                name: 'endpoint-shared-template-create',
                path: 'shared/create',
                components: {
                    default: () => import(/* webpackChunkName: "website" */ "@/views/website/EndpointTemplateEditor.vue"),
                    sidebar: () => import(/* webpackChunkName: "website" */ "@/views/website/components/EndpointTemplateTree.vue"),
                },
            },
            // {
            //     name: 'website-content-import',
            //     path: ':object_type/import',
            //     components: {
            //         default: () => import(/* webpackChunkName: "website" */ "@/views/website/WebsiteContentImport.vue"),
            //         sidebar: () => import(/* webpackChunkName: "website" */ "@/views/website/components/ContentTree.vue"),
            //     },
            // },
        ]
    },
]