import axios from 'axios'
import AuthService from './AuthService';
import { useConfig } from '@/scripts/utils/config'
import { events, useEventBus } from '../utils/eventBus';
import { useToasties } from '@vratier/super-vue-ui';

let api = null
let currentVersion = null
const shouldRetry = async (r) => {
    try {
        switch (r.response.status) {
            case 401:
            case 419:
                await AuthService.csrf(true)
                return true
            default:
        }
    } catch (error) {
    }
    return false
}
const init = async () => {
    const appConfig = await useConfig()
    // dialogs = useDialogs()
    api = axios.create({
        baseURL: appConfig.apiUrl,
        withCredentials: true,
    })
    /*
     * Add a response interceptor
     */
    api.interceptors.response.use(
        (response) => {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            if(response.headers.hasOwnProperty('x-quattro-version')){
                if(!currentVersion){
                    currentVersion = response.headers['x-quattro-version']
                }else if(currentVersion != response.headers['x-quattro-version']){
                    sessionStorage.setItem(events.NEW_VERSION_AVAILABLE,"yes")
                    useEventBus().emit(events.NEW_VERSION_AVAILABLE)
                    // useToasties().toast({
                    //     title: 'New Quattro version',
                    //     subtitle: 'A new version of Quattro is available',
                    //     content: 'Refresh this page or <a href="javascript:document.location.reload();">click here</a> to update',
                    //     key:events.NEW_VERSION_AVAILABLE,
                    //     duration:0
                    // })
                }
            }
            return response;
          }, function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            return Promise.reject(error);
        }
    );
}
const request = async (config) => {
    try {
        if (!api) {
            await init()
        }
        return await api.request(config)
    } catch (error) {
        if (await shouldRetry(error)) {
            return await api.request(config)
        } else {
            throw error
        }
    }
}
export default {
    async download(url) {
        if (!api) {
            await init()
        }
        const response = await api({
            url,
            method: 'GET',
            responseType: 'blob'
        })
        const objectUrl = window.URL.createObjectURL(response.data)
        const link = document.createElement('a');
        link.href = objectUrl;
        let fileName = 'unknown';
        const contentDisposition = response.headers['content-disposition']
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="?([^" $]+)/);
            if (fileNameMatch && fileNameMatch.length === 2)
                fileName = fileNameMatch[1];
        }
        link.setAttribute('download', fileName);
        link.click();
        window.URL.revokeObjectURL(objectUrl);
        return true
    },
    async upload(fileInfo, progressHandler, successHandler) {
        if (!api) {
            await init()
        }
        try{
            const response = await api.post("/api/upload", {type:fileInfo.file.type})
            fileInfo.filename = response.data.filename
            await axios.put(response.data.upload_url, fileInfo.file, {
                headers:{
                    'x-amz-acl': 'private',
                    'Content-Type': fileInfo.file.type,
                },
                onUploadProgress:(e)=>{progressHandler(e, fileInfo)}
            });
            successHandler(fileInfo)
        }catch(error){
            alert('Quattro Error', state.errorMessage)
        }
    },
    async get(url, config) {
        return await request(Object.assign({url,method:'get'},config))
    },
    async post(url, data, config) {
        return await request(Object.assign({url,data,method:'post'},config))
    },
    async put(url, data, config) {
        return await request(Object.assign({url,data,method:'put'},config))
    },
    async del(url, data, config) {
        return await request(Object.assign({url,data,method:'delete'},config))
    }
};