import { MeiliSearch } from 'meilisearch'
import { QuattroConfig, useConfig } from '@/scripts/utils/config'
import { ref } from 'vue'

let client:MeiliSearch | null  = null
let config:QuattroConfig = null
async function getClient(){
  if(!config){
    config = await useConfig()
  }
  if(!client){
    client = new MeiliSearch({
      host: config!.search.host,
      apiKey: config!.search.key,
    })
  }
  return client
}
export function useSearch(index:string, filter?:string) {
    const failing = ref(false)
    async function search(q:string ) {
      try{
        const client = await getClient()
        let f = filter ? {filter} : undefined
        const result = await client.index(index).search(q, f)
        failing.value = false
        return result.hits
      }catch(x){
        failing.value = true
        return []
      }
    }
    async function getById(id: number) {
      try{
        const client = await getClient()
        const result = await client.index(index).getDocument(id)
        failing.value = false
        // emit('loaded',r)
        return result
      }catch(x){
        failing.value = true
        return {id, label:`[${id}]`}
      }
    }
    return {
      search,
      getById,
      status:{
        failing
      }
    }
}