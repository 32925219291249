
import { h } from "vue";
import auth from "../middleware/auth";
export default [
    {
        path: "/flow",
        name: "flow",
        meta: { middleware: [auth], title(){return 'Flow'} },
        component: () => import(/* webpackChunkName: "flow" */ "@/views/flow/FlowDashboard.vue"),
    },
    {
        path: "/flow/presence",
        meta: { middleware: [auth], title(){return 'Flow | Presence'} },
        component: () => import(/* webpackChunkName: "base" */ "@/views/base/BasicLayout.vue"),
        children: [
            {
                name: 'presence',
                path: '',
                components: {
                    default: () => import(/* webpackChunkName: "flow" */ "@/views/flow/FlowPresence.vue"),
                },
            },
        ]
    },
]