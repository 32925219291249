<template>
<SHTMLInput
  :modelValue="modelValue"
  :schema="$attrs.schema"
  :plugins="$attrs.plugins"
  :unwrap="$attrs.unwrap"
  @update:modelValue="$emit('update:modelValue',$event)">
    <template #linkPicker="{updateField,getField}">
      <LinkPicker :url="href || getField('href')" @update:url="href = $event;updateField('href',$event)"/>
    </template>
</SHTMLInput>
</template>

<script lang="ts">
import { SHTMLInput } from '@vratier/super-vue-ui';
import { defineComponent, ref } from "vue";
import LinkPicker from '../base/components/LinkPicker.vue';

export default defineComponent({
  name: 'HTMLInput',
  components:{ SHTMLInput, LinkPicker },
  inheritAttrs: false,
  props: {
    modelValue: null
  },
  setup(){
    return {
      href:ref('')
    }
  }
})
</script>