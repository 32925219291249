<template>
  <div class="col-span-3 grid grid-cols-thumbnail">
    <div class="aspect-w-1 aspect-h-1" v-if="url">
      <a class=" flex justify-center items-center max-h-full bg-checkered hover:bg-btn-hover hover:text-brand" @click.prevent="showVersion == 'desktop' ? selectImage() : selectMobileImage()" ref="link">
        <img v-if="url" @click.prevent="selectImage" :src="url" :alt="alt" class="bg-transparent max-h-full overflow-hidden" :class="showVersion == 'desktop' ? '' :'hidden'"/>
        <img v-if="url_mob" @click.prevent="selectMobileImage" :src="url_mob" :alt="alt" class="bg-transparent max-h-full overflow-hidden" :class="showVersion == 'mobile' ? '' :'hidden'"/>
        <span v-if="!url && !url_mob">No image</span>
        <div v-if="url && url_mob" class="absolute bottom-0 left-0 right-0 text-center">
          <span @mouseenter="showVersion = 'desktop'" :class="showVersion == 'desktop' ? 'text-brand':'text-white'">
            <svg xmlns="http://www.w3.org/2000/svg" class="inline-block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
          </span><span class="text-gray-400 cursor-default"> / </span><span @mouseenter="showVersion = 'mobile'" :class="showVersion == 'mobile' ? 'text-brand':'text-white'">
            <svg xmlns="http://www.w3.org/2000/svg" class="inline-block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
            </svg>
          </span>
        </div>
      </a>
    </div>
    <div :class="url ? 'pl-3' : ''">
      <div>
        <SField label="Image URL" :errors="errors" :errorKey="errorKey('image_url')">
          <STextInput @click:icon="selectImage" icon="photograph" :disabled="disabled" :modelValue="url" @update:modelValue="updateValue('url',$event)" />
        </SField>
      </div>
      <div v-if="w_mob && h_mob">
        <SField label="Mobile image URL" :errors="errors" :errorKey="errorKey('image_url_mob')">
          <STextInput @click:icon="selectMobileImage" icon="photograph" :disabled="disabled" :modelValue="url_mob" @update:modelValue="updateValue('url_mob',$event)" />
        </SField>
      </div>
      <div>
        <SField v-if="url" label="Image alt tag" :errors="errors" :errorKey="errorKey('image_alt')">
          <STextInput :disabled="disabled" :modelValue="alt" @update:modelValue="$emit('update:alt',$event)" />
        </SField>
      </div>
      <div>
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
import imagePickerCallback from "@/scripts/utils/imagePicker";
import { defineComponent, ref } from '@vue/runtime-core'

export default defineComponent({
  props: {
    disabled: { type: Boolean, default: false },
    url: null,
    url_mob:null,
    alt: null,
    errors: null,
    errorKeys: null,
    w: { type: [Number,String], default: 800 },
    h: { type: [Number,String], default: 600 },
    w_mob: { type: [Number,String], default: null },
    h_mob: { type: [Number,String], default: null },
  },
  setup(props, { emit }) {
    const showVersion = ref('desktop')
    const link = ref(null)
    const selectImage = () => {
      const picked = (value, data) => {
        emit('update:url', value)
      }
      let value = `/${props.w}x${props.h}/`;
      if(props.url){
        value = props.url.replace(/\.com\/[0-9]+x[0-9]+\//,'.com'+value)
      }
      imagePickerCallback(picked, value);
      if(link.value){
        link.value.focus()
      }
    }
    const selectMobileImage = () => {
      const picked = (value, data) => {
        emit('update:url_mob', value)
      }
      let value = `/${props.w_mob}x${props.h_mob}/`;
      if(props.url_mob){
        value = props.url_mob.replace(/\.com\/[0-9]+x[0-9]+\//,'.com'+value)
      }else if(props.url){
        value = props.url.replace(/\.com\/[0-9]+x[0-9]+\//,'.com'+value)
      }
      imagePickerCallback(picked, value);
      if(link.value){
        link.value.focus()
      }
    }
    return {
      updateValue(k, v){
        if(!props.url_mob){
          showVersion.value = 'desktop'
        }
        emit('update:'+k,v)
      },
      link,showVersion,
      errorKey(key) {
        if(props.errorKeys){
          if(props.errorKeys.hasOwnProperty(key)){
            return props.errorKeys[key]
          }
        }
        return null
      },
      selectImage,selectMobileImage
    }
  }
})
</script>

