import api from "./ApiService"
function getResourcePath(object_type, isWebContent){
    return isWebContent ? `api/website-contents/${object_type.replace('_','-')}` : `api/${object_type}`
}
export default {
    async loadUrl(url) {
        const response = await api.get(`api/${url}`)
        if (response && response.status == 200) {
            return response.data
        }
        console.error(response)
        throw new Error(`Failed to load ${url} (${response.status})`)
    },
    async load(object_type, id, isWebContent = false) {
        if (id) {
            const response = await api.get(`${getResourcePath(object_type, isWebContent)}\/${id}`)
            if (response && response.status == 200) {
                return response.data
            }
            console.error(response)
            throw new Error(`Failed to load ${object_type} #${id} (${response.status})`)
        } else {
            const response = await api.get(`${getResourcePath(object_type, isWebContent)}\/${id}/create`)
            if (response && response.status == 200) {
                return response.data
            }
            console.error(response)
            throw new Error(`Failed to create new ${object_type} (${response.status})`)
        }
    },
    async createObject(object_type, data, isWebContent = false) {
        const response = await api.get(`${getResourcePath(object_type, isWebContent)}\/create`, {params:data})
        if (response && response.status == 200) {
            return response.data
        }
        console.error(response)
        throw new Error(`Failed to create ${object_type} object -> ${response.status}`)
    },
    async save(object_type, obj, isWebContent = false) {
        if (obj.id) {
            const response = await api.put(`${getResourcePath(object_type, isWebContent)}\/${obj.id}`, obj)
            if (response && response.status == 200) {
                return response.data
            }
        } else {
            const response = await api.post(`${getResourcePath(object_type, isWebContent)}`, obj)
            if (response && response.status == 201) {
                return response.data
            }
        }
    }
}