<template>
  <div>
    <div class="flex space-x-1">
      <SSearchSelect :search="search.search" :getById="search.getById" @update:modelValue="$emit('update:url', $event)" :itemId="o=>o.path">
        <SIcon icon="link"/>
      </SSearchSelect>
      <STextInput placeholder="Link URL" class="grow" :disabled="disabled" :modelValue="url" @update:modelValue="$emit('update:url', $event)" />
    </div>
    <STextInput class="mt-1" placeholder="Link title" v-if="$attrs.hasOwnProperty('onUpdate:title') && url" :disabled="disabled" :modelValue="title" @update:modelValue="$emit('update:title', $event)" />
    <STextInput class="mt-1" placeholder="Link text" v-if="$attrs.hasOwnProperty('onUpdate:text') && url" :disabled="disabled" :modelValue="text" @update:modelValue="$emit('update:text', $event)" />
  </div>
</template>
<script>
import { useSearch } from '@/scripts/utils/search'
import { defineComponent} from '@vue/runtime-core'

export default defineComponent({
  name:'LinkPicker',
  props: {
    disabled: { type: Boolean, default: false },
    url: null,
    text: null,
    title: null,
    errors: null,
    errorKeys: null,
  },
  setup(props, { emit }) {
    return {
      search: useSearch('website_contents')
    }
  }
})
</script>

