
import guest from "../middleware/guest";
export default [
    {
        path: "/login",
        name: "login",
        meta: { middleware: [guest] },
        component: () => import(/* webpackChunkName: "login" */ "@/views/auth/Login.vue"),
    },
    {
        path: "/logout",
        name: "logout",
        meta: { middleware: [guest] },
        component: () => import(/* webpackChunkName: "login" */ "@/views/auth/Logout.vue"),
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        meta: { middleware: [guest] },
        component: () => import(/* webpackChunkName: "login" */ "@/views/auth/ForgotPassword.vue"),
    },
    {
        path: "/reset-password",
        name: "reset-password",
        meta: { middleware: [guest] },
        component: () => import(/* webpackChunkName: "login" */ "@/views/auth/ResetPassword.vue"),
    }
]