import Pusher from 'pusher-js';
import { useConfig } from '@/scripts/utils/config'
import AuthService from '@/scripts/services/AuthService'
import axios from 'axios';
import { useToasties } from '@vratier/super-vue-ui';
import {events, useEventBus} from '../utils/eventBus'
import store from "../store";
if (import.meta.env.DEV && import.meta.env.VITE_LOG_PUSHER == "yes") {
    Pusher.logToConsole = true
}
let pusher: Pusher | null = null
export async function initBroadcasting() {
    const appConfig = await useConfig()
    if (appConfig) {
        await AuthService.csrf()
        pusher = new Pusher(appConfig.websockets.key, {
            cluster: appConfig.websockets.cluster,
            authorizer: (channel, options) => {
                return {
                    authorize: (socketId, callback) => {
                        axios.create({
                            baseURL: appConfig.apiUrl,
                            withCredentials: true,
                        }).post(appConfig.websockets.authEndpoint, {
                            socket_id: socketId,
                            channel_name: channel.name
                        }).then(response => {
                            callback(null, response.data);
                        })
                            .catch(error => {
                                callback(error, error);
                            });
                    }
                };
            }
        })
    }
}
export function disconnect() {
    pusher?.unsubscribe('presence-quattro')
}
export async function connect(user: any) {
    await AuthService.csrf()
    try {
        const presence = pusher?.subscribe('presence-quattro')
        presence?.bind('pusher:subscription_succeeded', function (data: any) {
            store.dispatch('flow/enter', { room: 'quattro', data })
        })
            .bind('pusher:member_added', function (member: any) {
                store.dispatch('flow/updatePresence', { user: member.info, provider: 'quattro', status:'Present' })
            })
            .bind('pusher:member_removed', function (member: any) {
                store.dispatch('flow/updatePresence', { user: member.info, provider: 'quattro', status:'Away' })
            })
            .bind("App\\Events\\PresenceEvent", (e: any) => {
                store.dispatch('flow/updatePresence', { user: e.user, provider: e.provider, status: e.status})
            })
        let channel = pusher?.subscribe(`private-users.${user.id}`)
        channel?.bind("App\\Events\\TechnicalEvent", (e: any) => {
            useToasties().toast({
                title: e.title,
                subtitle: e.subTitle
            })
            if(e.clientEvent){
                if(events.hasOwnProperty(e.clientEvent)){
                    useEventBus().emit((events as any)[e.clientEvent])
                }else{
                    console.error('Unhandled clientEvent: %s', e.clientEvent)
                }
            }
        })
            .bind("Illuminate\\Notifications\\Events\\BroadcastNotificationCreated", (notification: any) => {
                const prefix = 'App\\Notifications\\'
                if (notification.type == prefix + 'GenericNotification') {
                    useToasties().toast({
                        title: notification.title,
                        subtitle: notification.message
                    })
                } else {
                    console.error('Unhandled notification', notification)
                }
            });

    } catch (x) {
        console.error(x)
    }
}