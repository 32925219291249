
import { h } from "vue";
import auth from "../middleware/auth";
export default [
    {
        path: "/admin",
        name: "admin",
        meta: { middleware: [auth], title(){return 'Admin'} },
        component: () => import(/* webpackChunkName: "admin" */ "@/views/admin/AdminDashboard.vue"),
    },
    {
        path: "/admin/settings",
        meta: { middleware: [auth], title(){return 'Admin | Settings'} },
        component: () => import(/* webpackChunkName: "base" */ "@/views/base/BasicLayout.vue"),
        children: [
            {
                name: 'settings',
                path: '',
                components: {
                    default: () => import(/* webpackChunkName: "admin" */ "@/views/admin/SettingList.vue"),
                },
            },
            {
                name: 'setting', path: ':setting_id',
                components: {
                    default: () => import(/* webpackChunkName: "admin" */ "@/views/admin/SettingEditor.vue"),
                },
            },
            {
                path: "create",
                name: "setting:create",
                meta: { middleware: [auth] },
                components: {
                    default: () => import(/* webpackChunkName: "admin" */ "@/views/admin/SettingEditor.vue"),

                },
            },
        ]
    },
    {
        path: "/admin/themes",
        meta: { middleware: [auth], title(){return 'Admin | Themes'} },
        component: () => import(/* webpackChunkName: "base" */ "@/views/base/BasicLayout.vue"),
        children: [
            {
                name: 'themes',
                path: '',
                components: {
                    default: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ThemeList.vue"),
                },
            },
            {
                name: 'theme', path: ':theme_id',
                components: {
                    default: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ThemeEditor.vue"),
                }
            },
            {
                path: "create",
                name: "theme:create",
                meta: { middleware: [auth] },
                components: {
                    default: () => import(/* webpackChunkName: "admin" */ "@/views/admin/ThemeEditor.vue"),

                },
            },
        ]
    },
    {
        path: "/admin/themes/:theme_id/templates",
        meta: { middleware: [auth], title(){return 'Admin | Templates'} },
        component: () => import(/* webpackChunkName: "base" */ "@/views/base/BasicLayout.vue"),
        children: [
            {
                name: 'theme-template', path: ':template_id',
                components: {
                    default: () => import(/* webpackChunkName: "admin" */ "@/views/admin/TemplateEditor.vue"),
                },
            },
            {
                path: "create",
                name: "theme-template:create",
                meta: { middleware: [auth] },
                components: {
                    default: () => import(/* webpackChunkName: "admin" */ "@/views/admin/TemplateEditor.vue"),

                },
            },
        ]
    },
]