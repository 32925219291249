
import { h } from "vue";
import auth from "../middleware/auth";
export default [
    {
        path: "/second-screen",
        name: "second-screen",
        meta: { middleware: [auth], title(){return 'Quattro'} },
        component: () => import(/* webpackChunkName: "flow" */ "@/views/secondScreen/SecondScreenLayout.vue"),
    },
]