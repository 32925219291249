const handleKeydown = (e) => {
    if (!e.repeat && (((e.ctrlKey || e.altKey || e.shiftKey || e.metaKey) && !e.key.match(/(Control|Shift|Meta|Alt)/)) || e.key == 'Escape')) {
        const key = `${e.ctrlKey ? 'ctrl+' : ''}${e.altKey ? 'alt+' : ''}${e.shiftKey ? 'shift+' : ''}${e.metaKey ? 'meta+' : ''}${e.key.toLowerCase()}`
        if (handlers.has(key) && handlers.get(key).length > 0) {
            handlers.get(key)[handlers.get(key).length - 1]()
            e.preventDefault()
            return false
        }
    }
}
window.addEventListener('keydown', handleKeydown)
const handlers = new Map()

export function useShortcuts(key, handler) {
    if (handlers.has(key)) {
        handlers.get(key).push(handler)
    } else {
        handlers.set(key, [handler])
    }
}
export function unuseShortcuts(key, handler) {
    if (handlers.has(key)) {
        handlers.get(key).splice(handlers.get(key).indexOf(handler), 1)
    }
}