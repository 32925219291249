<template>
  <SSearchSelect :search="search.search" nullable :getById="search.getById" :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)" :itemId="o => o.id">
  </SSearchSelect>
</template>
<script>
import { useSearch } from '@/scripts/utils/search'
import { defineComponent } from '@vue/runtime-core'

export default defineComponent({
  name: 'WebContentPicker',
  props: {
    disabled: { type: Boolean, default: false },
    modelValue: null,
    filter: null,
    errors: null,
    errorKeys: null,
  },
  setup(props, { emit }) {
    return {
      search: useSearch('website_contents', props.filter)
    }
  }
})
</script>
