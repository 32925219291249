import axios from 'axios';
import store from "../store";
import { useConfig } from '@/scripts/utils/config'
import SimplePromptDialogVue from '@/views/base/components/SimplePromptDialog.vue';
import { useDialogs } from "@vratier/super-vue-ui";
import { markRaw } from 'vue';

let authClient = null
function getXsrfToken() {
    const parts = `; ${document.cookie}`.split(`; XSRF-TOKEN=`);
    return (parts.length === 2) ? parts.pop().split(';').shift() : null
}
const csrf = async (forceRefresh) => {
    if (!authClient) {
        await init()
    }
    let token = getXsrfToken()
    if (!token || forceRefresh) {
        token = await authClient.get("/sanctum/csrf-cookie");
        return token
    }
    return token

}
export async function init() {
    const appConfig = await useConfig()
    authClient = axios.create({
        baseURL: appConfig.apiUrl,
        withCredentials: true,
    });

    /*
     * Add a response interceptor
     */
    authClient.interceptors.response.use(
        (response) => {
            return response;
        },
        function (error) {
            if (
                error.response &&
                [401, 419].includes(error.response.status) &&
                store.getters["auth/me"] &&
                !store.getters["auth/guest"]
            ) {
                store.dispatch("auth/logout");
                document.cookie = "XSRF-TOKEN=; Max-Age=-1; path=/; domain=" + appConfig.session.domain
            }
            return Promise.reject(error);
        }
    );
}
export default {
    csrf,
    async resetPassword(payload) {
        await csrf()
        return authClient.post("/reset-password", payload);
    },
    async login(payload) {
        await csrf()
        let response = await authClient.post("/login", payload);
        if (response.status == 200) {
            if (response.data.two_factor) {
                return await new Promise((resolve,reject)=>{
                    useDialogs().dialog({
                        props: {
                            label: "2FA code?"
                        },
                        buttons: [
                            {
                                label: 'Confirm', color: 'success', action: 'confirm'
                            },
                            {
                                label: 'Cancel', action: 'cancel'
                            }
                        ],
                        component: markRaw(SimplePromptDialogVue),
                        async onAction(actionInfo, dialog) {
                            if (actionInfo.code == 'confirm') {
                                if (actionInfo.component.value) {
                                    const response = await authClient.post("/two-factor-challenge", { code:actionInfo.component.value });
                                    if (response.status != 204) {
                                        reject()
                                    }else{
                                        resolve(true)
                                    }
                                    dialog.closeDialog()
                                }
                            } else {
                                dialog.closeDialog()
                                resolve(false)
                            }
                        }
                    })
                })
            }else{
                return true
            }
        }
        return false
    },
    async logout() {
        if (!authClient) {
            await init()
        }
        return authClient.post("/logout");
    },
    async forgotPassword(payload) {
        await csrf()
        return authClient.post("/forgot-password", payload);
    },
    async getMe() {
        await csrf()
        let response = await authClient.get("/api/me");
        return response.data
    },
    async saveUser(data) {
        await csrf()
        let response = await authClient.put("/api/user/" + data.id, data);
        return response.data
    },
    async resetPassword(payload) {
        await csrf()
        return authClient.post("/reset-password", payload);
    },
    async updatePassword(payload) {
        await csrf()
        return authClient.put("/user/password", payload);
    },
    async sendVerification(payload) {
        await csrf()
        return authClient.post("/email/verification-notification", payload);
    },
    async updateUser(payload) {
        await csrf()
        return authClient.put("/user/profile-information", payload);
    },
}
