<template>
  <SSearchSelect :search="search.search" :getById="search.getById" :disabled="disabled || search.status.failing" :modelValue="modelValue" @update:modelValue="changed">
    <template #default="{ selection }">
      <span v-if="selection.length == 0" v-text="emptyLabel" />
      <div class="inline-block overflow-ellipsis whitespace-nowrap pr-6" v-else>{{ selection.map((o) => o.label).join(', ') }}</div>
    </template>
  </SSearchSelect>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useSearch } from "@/scripts/utils/search";

export default defineComponent({
  name: 'SearchSelect',
  props: {
    modelValue: null,
    index: null,
    filter: null,
    emptyLabel: null,
    disabled: { type: Boolean, default: false },
    pick: { type: Boolean, default: false },
  },
  emits:['picked', 'loaded', 'update:modelValue'],
  async setup(props, { emit }) {
    const search = await useSearch(props.index, props.filter)
    return {
      changed(v: any) {
        if (props.pick) {
          emit('picked', v)
        }else{
          emit('update:modelValue', v)
        }
      },
      search
    }
  }
})
</script>