import Flow from "@/scripts/services/FlowService";

export default {
    namespaced: true,
    state: () => ({
        users: [],
    }),
    mutations: {
        SET_USERS(state, users) {
            state.users.splice(0);
            state.users.push(...users);
        },
        UPDATE_USER(state, { index, user, statuses }) {
            for (const key in user) {
                state.users[index][key] = user[key];
            }
            if (statuses) {
                for (const key in statuses) {
                    state.users[index].statuses[key] = statuses[key];
                }
            }
        },
        ADD_USER(state, { user, statuses }) {
            for (const key in statuses) {
                user.statuses[key] = statuses[key];
            }
            state.users.push(user);
        },
    },
    actions: {
        async enter({ state, commit, dispatch }, { room, data }) {
            if (state.users.length == 0) {
                await dispatch("loadUsers");
            }
            if (room == "quattro") {
                const members = Object.keys(data.members).map(function (k) {
                    return data.members[k];
                });
                members.forEach((user) => {
                    const index = state.users.findIndex((u) => u.id == user.id);
                    if (index >= 0) {
                        commit("UPDATE_USER", {
                            index,
                            user,
                            statuses: { quattro: "Present" },
                        });
                    } else {
                        commit("ADD_USER", {
                            user,
                            statuses: { quattro: "Present" },
                        });
                    }
                });
            }
        },
        async updatePresence({ state, commit }, { user, provider, status }) {
            const index = state.users.findIndex((u) => u.id == user.id);
            const statuses = {};
            statuses[provider] = status;
            if (index < 0) {
                commit("ADD_USER", { user, statuses });
            } else {
                commit("UPDATE_USER", { index, user, statuses });
            }
        },
        async loadUsers({ state, commit }) {
            try {
                const users = await Flow.loadUsers();
                users.forEach((user) => {
                    const index = state.users.findIndex((u) => u.id == user.id);
                    if (index >= 0) {
                        commit("UPDATE_USER", {
                            index,
                            user,
                            statuses: { quattro: "Away" },
                        });
                    } else {
                        commit("ADD_USER", {
                            user,
                            statuses: { quattro: "Away" },
                        });
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },
    },
};
