import { reactive } from 'vue'
let config:QuattroConfig = null
export type QuattroConfig =  {
    apiUrl: string,
    search:{
        host:string,
        key:string
    },
    websockets:{
        authEndpoint: string,
        cluster: string,
        key: string
    },
    session: {
        domain: string
    }
} | null
export async function useConfig(forceReload = false) {
    if (!config || forceReload) {
        return new Promise<QuattroConfig>((resolve, reject) => {
            fetch('/config.json', {cache: "reload"})
                .then(res => res.json())
                .then(data => {
                    config = data
                    resolve(config)
                })
        })
    }else{
        return config
    }
}