import { createApp, h } from "vue";
import App from "@/views/App.vue";
import { SuperVueUI, SCodeInput, SCoordinates } from "@vratier/super-vue-ui";
import store from "./store";
import router from "./routes";
import "@/css/style.css";
import "@vratier/super-vue-ui/dist/components.css";

import imagePickerCallback from "@/scripts/utils/imagePicker";

import ImagePicker from "@/views/base/components/ImagePicker.vue";
import LinkPicker from "@/views/base/components/LinkPicker.vue";
import WebContentPicker from "@/views/base/components/WebContentPicker.vue";
import Editor from "@/views/base/components/Editor.vue";
import SearchSelect from "@/views/components/SearchSelect.vue";
import { useConfig } from "./utils/config";
import HTMLInput from "@/views/components/HTMLInput.vue";
import { initBroadcasting } from "@/scripts/services/BroadcastingService";
import Separator from "@/views/components/Separator.vue";
useConfig().then((appConfig) => {
    initBroadcasting();
    const app = createApp(App)
        .component("SCodeInput", SCodeInput)
        .component("HTMLInput", HTMLInput)
        .component("SCoordinates", SCoordinates)
        .component("OrSeparator", Separator)

        .component("ImagePicker", ImagePicker)
        .component("WebContentPicker", WebContentPicker)
        .component("LinkPicker", LinkPicker)
        .component("SearchSelect", SearchSelect)
        .component("Editor", Editor)

        .use(SuperVueUI, {
            tokens: {
                mapbox: appConfig.tokens.mapbox,
            },
        })

        .use(router)
        .use(store);
    app.config.globalProperties.$quattro = {
        config: appConfig,
    };
    app.mount("#app");
});
//
window.imagePicker = (el) => {
    imagePickerCallback((v) => {
        el.value = v;
    });
};
