export default function (cb, value, meta) {
    if (typeof meta !== "undefined" && meta.filetype == "file") {
        cb("/", { title: "Home" });
    }
    let pickerElement = top.document.getElementById("sa-photopicker");
    var width = null;
    var height = null;
    var imageId = null;
    if (value) {
        let match = value.match(
            /media.selectiveasia.com\/(?:([0-9]+)(?:x([0-9]+))?\/(?:.*\/)?)?([0-9]+)\.(?:jpg|png|jpeg|webp)/
        );
        if (match) {
            width = match[1];
            height = match[2];
            imageId = match[3];
        } else {
            match = value.match(/^\/([0-9]+)x([0-9]+)\/$/);
            if (match) {
                width = match[1];
                height = match[2];
            } else {
                match = value.match(/^\/([0-9]+)\/$/);
                if (match) {
                    width = match[1];
                }
            }
        }
    }
    var msgHandler = function (event) {
        if (event.data.action == "cancelSAImagePicker") {
            closePicker();
        } else if (event.data.action == "selectSAImagePicker") {
            // selected image(s)
            handleSelection(event.data.selection);
        } else if (event.data.action == "checkForSAImagePickerHandler") {
            // check setup
            // reply to the picker frame with the appropriate message (checkForSAImagePickerHandlerOk)
            pickerElement.contentWindow.postMessage(
                { action: "checkForSAImagePickerHandlerOk" },
                "*"
            );
        }
    };
    function handleSelection(selectionData) {
        /*
        The result is:
        - a JSON object if a single image was selected: {url:"http://example.com"}
        - an JSON array if multiple images were selected: [{url:"http://example.com"},{url:"http://example.com"}]
        */
        cb(selectionData.url, "");
        closePicker();
    }
    function closePicker() {
        top.removeEventListener("message", msgHandler, false);
        top.document.getElementById("sa-photopicker-overlay").style.display =
            "none";
        pickerElement.src = "";
        top.document.getElementsByTagName("body")[0].style.overflowY = "auto";
        top.focus();
    }
    top.addEventListener("message", msgHandler, false);

    if (imageId) {
        if (width && height) {
            pickerElement.src =
                "https://quattro.selectiveasia.com/image-picker?i=" +
                imageId +
                "&w=" +
                width +
                "&h=" +
                height;
        } else if (width) {
            pickerElement.src =
                "https://quattro.selectiveasia.com/image-picker?i=" +
                imageId +
                "&w=" +
                width;
        } else {
            pickerElement.src =
                "https://quattro.selectiveasia.com/image-picker?i=" + imageId;
        }
    } else {
        if (width && height) {
            pickerElement.src =
                "https://quattro.selectiveasia.com/image-picker?w=" +
                width +
                "&h=" +
                height;
        } else if (width) {
            pickerElement.src =
                "https://quattro.selectiveasia.com/image-picker?w=" + width;
        } else {
            pickerElement.src =
                "https://quattro.selectiveasia.com/image-picker";
        }
    }
    top.document.getElementById("sa-photopicker-overlay").style.display =
        "block";
    top.document.getElementsByTagName("body")[0].style.overflowY = "hidden";
}
