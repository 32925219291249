import router from '../../routes'
import AuthService from '@/scripts/services/AuthService';
import {connect, disconnect} from '@/scripts/services/BroadcastingService'
async function load({ commit, dispatch }, payload, action, errorHandler) {
    commit("SET_LOADING", true);
    try {
        const result = await action()
        commit("SET_LOADING", false)
        return result
    } catch (error) {
        if (errorHandler) {
            await errorHandler(error)
        }
        commit("SET_LOADING", false);
        throw error
    }
}
export default {
    namespaced: true,
    state: () => ({
        me: null,
        loading: false,
    }),
    mutations: {
        SET_ME(state, me) {
            state.me = me;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
    },
    actions: {
        async login(context, payload) {
            return load(context, payload, async () => {
                if(await AuthService.login(payload)){
                    context.dispatch("setGuest", { value: "isNotGuest" })
                    return true
                }
                return false
            })
        },
        async resetPassword(context, payload) {
            return load(context, payload, async () => {
                const response = await AuthService.resetPassword(payload)
                return response
            })
        },
        async logout({ commit, dispatch }) {
            commit("SET_LOADING", true);
            try {
                const response = await AuthService.logout();
                disconnect()
                commit("SET_ME", null);
                dispatch("setGuest", { value: "isGuest" });
                if (router.currentRoute.name !== "login")
                    router.push({ path: "/login" });
                commit("SET_LOADING", false);
            } catch (error) {
                commit("SET_LOADING", false);
                throw error
            }
        },
        async loadMe({ commit, dispatch }) {
            commit("SET_LOADING", true);
            try {
                const user = await AuthService.getMe();
                connect(user)
                commit("SET_ME", user);
                commit("SET_LOADING", false);
                return user;
            } catch (error) {
                dispatch("logout")
                disconnect()
                commit("SET_ME", null);
                commit("SET_LOADING", false);
            }
        },
        setGuest(context, { value }) {
            window.localStorage.setItem("guest", value);
        },
    },
    getters: {
        me: (state) => {
            return state.me;
        },
        guest: () => {
            const storageItem = window.localStorage.getItem("guest");
            if (!storageItem) return false;
            if (storageItem === "isGuest") return true;
            if (storageItem === "isNotGuest") return false;
        },
    }
}