import WebsiteService from "@/scripts/services/WebsiteService";

function treeify(nodes) {
    const nodesByPath = new Map()
    const nodesById = new Map()
    const orphans = []
    for (let i = 0; i < nodes.length; i++) {
        nodes[i].children = []
        nodesByPath.set(nodes[i].path, nodes[i])
        nodesById.set(nodes[i].id, nodes[i])
    }
    for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].path != '/') {
            const parentPath = nodes[i].path.replace(/^(.*)\/[^\/]+/, '$1') || '/'
            const parent = nodesByPath.get(parentPath)
            if (parent) {
                parent.children.push(nodes[i])
            } else {
                orphans.push(nodes[i])
            }
        }
    }
    return { tree: nodesByPath.get('/'), index: nodesByPath, indexById: nodesById, orphans: orphans }
}
const fixComponent = (d, t, p) => {
    if (t.startsWith('array:')) {
        const dataDesc = p[t.replace(/^array:/, '')]
        if (d) {
            return d
        } else {
            return null
        }
    } else {
        const dataDesc = p[t]
        switch (dataDesc._t) {
            case 'array':
                const fixedArray = []
                if (typeof d == typeof []) {
                    d.forEach(e => {
                        fixedArray.push(e)
                    });
                }
                for (let i = fixedArray.length; i < dataDesc._s[0]; i++) {
                    const fixedObject = {}
                    for (const key in dataDesc.props) {
                        fixedObject[key] = fixComponent(null, key, dataDesc.props)
                    }
                    fixedArray.push(fixedObject)
                }
                return fixedArray
            case 'object':
                const fixedObject = Object.assign({}, d)
                for (const key in dataDesc.props) {
                    fixedObject[key] = fixComponent(fixedObject[key], key, dataDesc.props)
                }
                return fixedObject
            default:
                return d || null
        }
    }
}
const fixContent = (c, p) => {
    if (c.page_template) {
        for (const ix in c.page_template.data.components) {
            c.data[c.page_template.data.components[ix].code] = fixComponent(c.data[c.page_template.data.components[ix].code], c.page_template.data.components[ix].type, p)
        }
    }
    return c
}
export default {
    namespaced: true,
    state: () => ({
        templates: null,
        openNodes: null,
        openContentNodes: null,
        contentTree: null,
        templateTree: null,
        tree: null,
        theme: null,
        orphans: null,
        index: null,
        indexById: null,
        loading: false,
        stashedData: null,
        headerComponents: [
            'banner'
        ]
    }),
    mutations: {
        SET_STASHED(state, stashedData) {
            state.stashedData = stashedData;
        },
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
        SET_TEMPLATES(state, templates) {
            state.templates = templates
        },
        SET_THEME(state, theme) {
            state.theme = theme
        },
        SET_TREE(state, tree) {
            state.tree = tree
        },
        SET_TEMPLATE_TREE(state, templateTree) {
            state.templateTree = templateTree
        },
        SET_CONTENT_TREE(state, contentTree) {
            state.contentTree = contentTree
        },
        SET_OPEN_NODES(state, openNodes) {
            state.openNodes = openNodes
        },
        SET_NODE_OPENED(state, nodeId) {
            if (!state.openNodes.has(nodeId)) {
                state.openNodes.add(nodeId)
            }
        },
        SET_NODE_CLOSED(state, nodeId) {
            if (state.openNodes.delete(nodeId)) {
            }
        },
        SET_OPEN_TEMPLATE_NODES(state, openTemplateNodes) {
            state.openTemplateNodes = openTemplateNodes
        },
        SET_TEMPLATE_NODE_OPENED(state, nodeId) {
            if (!state.openTemplateNodes.has(nodeId)) {
                state.openTemplateNodes.add(nodeId)
            }
        },
        SET_TEMPLATE_NODE_CLOSED(state, nodeId) {
            if (state.openTemplateNodes.delete(nodeId)) {
            }
        },
        SET_OPEN_CONTENT_NODES(state, openContentNodes) {
            state.openContentNodes = openContentNodes
        },
        SET_CONTENT_NODE_OPENED(state, nodeId) {
            if (!state.openContentNodes.has(nodeId)) {
                state.openContentNodes.add(nodeId)
            }
        },
        SET_CONTENT_NODE_CLOSED(state, nodeId) {
            if (state.openContentNodes.delete(nodeId)) {
            }
        },
        SET_ORPHANS(state, orphans) {
            state.orphans = orphans
        },
        SET_INDEX(state, index) {
            state.index = index
        },
        SET_INDEX_BY_ID(state, index) {
            state.indexById = index
        },
    },
    actions: {
        openNode({ commit, state }, nodeId) {
            commit("SET_NODE_OPENED", nodeId);
            localStorage.setItem('openNodes', JSON.stringify([...state.openNodes]))
        },
        closeNode({ commit, state }, nodeId) {
            commit("SET_NODE_CLOSED", nodeId);
            localStorage.setItem('openNodes', JSON.stringify([...state.openNodes]))
        },
        collapseContentTree({ commit, state }) {
            commit("SET_OPEN_CONTENT_NODES", new Set());
            localStorage.setItem('openNodes', JSON.stringify([...state.openContentNodes]))
        },
        openContentNode({ commit, state }, nodeId) {
            commit("SET_CONTENT_NODE_OPENED", nodeId);
            localStorage.setItem('openContentNodes', JSON.stringify([...state.openContentNodes]))
        },
        closeContentNode({ commit, state }, nodeId) {
            commit("SET_CONTENT_NODE_CLOSED", nodeId);
            localStorage.setItem('openContentNodes', JSON.stringify([...state.openContentNodes]))
        },
        openTemplateNode({ commit, state }, nodeId) {
            commit("SET_TEMPLATE_NODE_OPENED", nodeId);
            localStorage.setItem('openTemplateNodes', JSON.stringify([...state.openTemplateNodes]))
        },
        closeTemplateNode({ commit, state }, nodeId) {
            commit("SET_TEMPLATE_NODE_CLOSED", nodeId);
            localStorage.setItem('openTemplateNodes', JSON.stringify([...state.openTemplateNodes]))
        },
        async loadTemplates({ commit }) {
            try {
                commit("SET_LOADING", true);
                const templates = await WebsiteService.loadTemplates();
                commit("SET_TEMPLATES", templates);
            } catch (error) {
                throw error
            } finally {
                commit("SET_LOADING", false);
            }
        },
        async loadTheme({ commit }) {
            try {
                commit("SET_LOADING", true);
                const theme = await WebsiteService.loadTheme();
                commit("SET_THEME", theme);
            } catch (error) {
                throw error
            } finally {
                commit("SET_LOADING", false);
            }
        },
        async loadTree({ commit }) {
            try {
                commit("SET_LOADING", true);
                const endpoints = await WebsiteService.loadTree();
                const data = treeify(endpoints)
                let openNodes = localStorage.getItem('openNodes')
                if (openNodes) {
                    openNodes = new Set(JSON.parse(openNodes))
                } else {
                    openNodes = new Set()
                }
                commit("SET_OPEN_NODES", openNodes);
                commit("SET_TREE", data.tree);
                commit("SET_ORPHANS", data.orphans);
                commit("SET_INDEX", data.index);
                commit("SET_INDEX_BY_ID", data.indexById);
            } catch (error) {
                throw error
            } finally {
                commit("SET_LOADING", false);
            }
        },
        async loadTemplateTree({ commit }) {
            try {
                commit("SET_LOADING", true);
                const templateTree = await WebsiteService.loadTemplateTree();
                let openTemplateNodes = localStorage.getItem('openTemplateNodes')
                if (openTemplateNodes) {
                    openTemplateNodes = new Set(JSON.parse(openTemplateNodes))
                } else {
                    openTemplateNodes = new Set()
                }
                commit("SET_OPEN_TEMPLATE_NODES", openTemplateNodes);
                commit("SET_TEMPLATE_TREE", templateTree);
            } catch (error) {
                throw error
            } finally {
                commit("SET_LOADING", false);
            }
        },
        async loadContentTree({ commit }) {
            try {
                commit("SET_LOADING", true);
                const contentTree = await WebsiteService.loadContentTree();
                let openContentNodes = localStorage.getItem('openContentNodes')
                if (openContentNodes) {
                    openContentNodes = new Set(JSON.parse(openContentNodes))
                } else {
                    openContentNodes = new Set()
                }
                commit("SET_OPEN_CONTENT_NODES", openContentNodes);
                commit("SET_CONTENT_TREE", contentTree);
            } catch (error) {
                throw error
            } finally {
                commit("SET_LOADING", false);
            }
        },
    }
}