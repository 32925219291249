
import { computed, reactive, onBeforeUnmount, ref } from 'vue'
import { useShortcuts, unuseShortcuts } from '@/scripts/utils/shortcuts'
import { useDialogs, useToasties, uuid } from '@vratier/super-vue-ui'
import { onBeforeRouteUpdate, onBeforeRouteLeave, RouteParams, RouteRecord } from "vue-router";
import { Store } from 'vuex';
import { isArray } from '../utils/utils';
import { useEventBus } from '../utils/eventBus';
import { createEditorStoreModule } from './EditoreStoreModuleFactory';
let dialogs = useDialogs()

export function createEditor(store, router, object_type: string, routeIdKey, routeActions, onSaveHandler, onBeforeSaveHandler, isWebContent, onObjectLoaded, notFoundRoute, reloadTriggers, mainEditor) {
    const subEditors = new Map()
    function mapStoreObject(ns: string, store: Store) {
        let so = {}
        const obj = store.state[ns].editedObject
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                so[key] = computed({
                    get() {
                        const o = store.state[ns].editedObject
                        return o ? o[key] : null
                    },
                    set(value) {
                        store.dispatch(`${ns}/updateObject`, { key, value })
                    }
                })
    
            }
        }
        return so
    }
    const ns = (isWebContent ? 'website_contents' : object_type) + '-' + uuid()
    store.registerModule(ns, createEditorStoreModule(mainEditor))
    const sub = !!mainEditor
    const touches = ref(0)
    const eventBus = useEventBus()
    if(!window.toasties){
        window.toasties = useToasties()
    }
    const reload = () => {
        if(store.state[ns].editedObject && store.state[ns].editedObject.id){
            if(store.state[ns].dirty){
                useToasties().toast({
                    title: 'Attention please',
                    subtitle: 'You are no longer working on the latest version of this document and your changes may be lost.',
                    color:'warning',
                    duration:0
                })
            }else{
                load(store.state[ns].editedObject.id)
            }
        }
    }
    if (reloadTriggers) {
        if (!isArray(reloadTriggers)) {
            reloadTriggers = [reloadTriggers]
        }
        reloadTriggers.forEach((trigger: string) => {
            eventBus.on(trigger, reload)
        });
    }
    const destroyEditor = () => {

        if (reloadTriggers) {
            reloadTriggers.forEach((trigger: string) => {
                eventBus.off(trigger, reload)
            });
            if (sub) {
                // Unregister sub editor
                subEditors.delete(ns)
            } else {
                mainEditor = null
                window.removeEventListener('beforeunload', handleBeforeUnload, false)
                unuseShortcuts('ctrl+s', save)
                unuseShortcuts('meta+s', save)
            }
            store.unregisterModule(ns)
        }
    }
    const getObjectClone = () => {
        return JSON.parse(JSON.stringify(store.state[ns].editedObject))
    }
    const save = async () => {
        if (store.state[ns].dirty) {
            if (onBeforeSaveHandler) {
                const canSave = await onBeforeSaveHandler(store.state[ns].editedObject)
                if (!canSave) {
                    return
                }
            }
            store.dispatch(`${ns}/clearErrors`)
            const isNew = !store.state[ns].editedObject.id
            const o = await store.dispatch(`${ns}/saveObject`, { object_type, obj: store.state[ns].editedObject, isWebContent })
            if (onSaveHandler) {
                onSaveHandler(o, isNew)
            }
            if (router && router.currentRoute && router.currentRoute.value.params[routeIdKey] != o.id) {
                let data = {}
                data[routeIdKey] = o.id
                let path = isWebContent ? 'website-content-item' : object_type.replace(/s$/, '')
                if (isWebContent) {
                    data['object_type'] = object_type.replace('_', '-')
                }
                if (isNew) {
                    router.replace({ name: path, params: data })
                } else {
                    router.push({ name: path, params: data })
                }
            }
            if (!sub) {
                subEditors.forEach((editor, key, _map) => {
                    editor.save()
                });
            }
            return o
        } else {
            if (!sub) {
                subEditors.forEach((editor, key, _map) => {
                    editor.save()
                });
            }
            return store.state[ns].editedObject
        }
    }
    const handleRouteChange = async (to, from?) => {
        if (!from || from.params[routeIdKey] != to.params[routeIdKey] || from.name != to.name) {
            if (!sub && editor.status.dirty.value) {
                const result = await dialogs.confirm('Unsaved changes', 'Please save or cancel before leaving this page', {
                    buttons: [
                        { label: 'Save changes', color: 'success', action: 'saveAndGo' },
                        { label: 'Discard changes', color: 'danger', action: 'confirm' },
                        { label: 'Stay here', color: 'btn', action: 'cancel' }
                    ],
                })
                result.closeDialog()
                switch (result.code) {
                    case 'confirm':
                        break;
                    case 'saveAndGo': {
                        await save()
                    }
                        break;
                    default:
                        return false
                }
            }
            if (routeActions && routeActions.hasOwnProperty(to.name)) {
                const action = routeActions[to.name](to, from, getObjectClone())
                if (action) {

                    const params = Object.assign({ object_type }, action.params, action.query, { isWebContent })
                    try {
                        let x = await store.dispatch(`${ns}/${action.action}`, params);
                        if ((action.action == 'openObject' || action.action == 'createObject' || action.action == 'openUrl') && onObjectLoaded) {
                            onObjectLoaded()
                        }
                        if (action.callback) {
                            action.callback(action.params, x, mainEditor)
                        }
                    } catch (error: any) {
                        if (error && error.isAxiosError && error.response && error.response.status == 404) {
                            if (notFoundRoute) {
                                router.replace(notFoundRoute)
                            } else {
                                let path = router.currentRoute.value.path
                                if (/.*\/([0-9]+|create)$/.test(path)) {
                                    router.replace(path.replace(/\/([0-9]+|create)$/, ''))
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    const handleBeforeUnload = (event) => {
        if (!sub && store.state[ns].dirty) {
            event.preventDefault();
            return event.returnValue = "Your unsaved changes will be lost";
        }
    }
    const load = (idToLoad: number) => {
        const o = store.dispatch(`${ns}/openObject`, { object_type, id: idToLoad, isWebContent: isWebContent })
        if (onObjectLoaded) {
            onObjectLoaded()
        }
        return o
    }
    const cancel = () => {
        if (store.state[ns].editedObject.id) {
            store.dispatch(`${ns}/openObject`, { object_type, id: store.state[ns].editedObject.id, callback: undefined, isWebContent: isWebContent })
        } else {
            store.dispatch(`${ns}/openObject`, { object_type, id: store.state[ns].editedObject.id, isWebContent: isWebContent })
        }
        if (onObjectLoaded) {
            onObjectLoaded()
        }

        if (!sub) {
            // cancel sub editors
            subEditors.forEach((editor, key, _map) => {
                editor.cancel()
            });
        }
    }
    window.addEventListener('beforeunload', handleBeforeUnload, false)
    onBeforeUnmount(destroyEditor)
    onBeforeRouteLeave(handleRouteChange)
    onBeforeRouteUpdate(handleRouteChange)
    const editor = {
        status: {
            dirty: computed(() => {
                const x = touches.value
                if (!sub) {
                    for (const [key, value] of subEditors.entries()) {
                        if (store.state[key].dirty) {
                            return true
                        }
                    }
                }
                return store.state[ns].dirty
            }),
            loading: computed(() => store.state[ns].loading),
            saving: computed(() => store.state[ns].saving),
            errorMessage: computed(() => store.state[ns].errorMessage),
            validationErrors: computed(() => store.state[ns].validationErrors),
        },
        getObjectClone,
        save,
        setStatus(status: Object) {
            store.dispatch(`${ns}/setStatus`, status)
        },
        load,
        cancel,
        mapObject() {
            const o = { ...mapStoreObject(ns, store) }
            return reactive(o)
        },
        async addToMany(type, fk, obj) {
            return await store.dispatch(`${ns}/addToMany`, type, fk, obj, object_type, isWebContent)
        },
        touch() {
            touches.value++
            // store.commit(`${ns}/setDirty`, true)
        },
        registerSubEditor(ns, editor){
            subEditors.set(ns, editor)
        },
        handleRouteChange, destroyEditor,
        isSub: sub
    }
    if (sub) {
        // Register sub editor
        mainEditor.registerSubEditor(ns, editor)
    } else {
        useShortcuts('ctrl+s', save)
        useShortcuts('meta+s', save)
    }
    return editor
}