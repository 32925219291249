import { createStore, createLogger } from 'vuex'
import auth from './modules/auth'
import website from './modules/website'
import flow from './modules/flow'
// Create a new store instance.
const settings = {
  strict: false,
  plugins:[]
}
if (import.meta.env.DEV) {
  settings.strict = true
  if(import.meta.env.VITE_LOG_VUEX == "yes"){
    settings.plugins.push(createLogger())
  }
}
const store = createStore({
  strict: settings.strict,
  plugins: settings.plugins,
  modules: {
    auth,
    website,
    flow,
  }
})
export default store