<template>
  <RouterView />
  <SDialogs />
  <SToasties />
</template>
<script>
import { defineComponent } from "@vue/runtime-core";
import { computed, ref } from 'vue'
export default defineComponent({
  setup() {
    const videos = []
    const loadingVideos = ref(true)
    function loadVideos(url) {
      var data = null;
      var xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      if (typeof url == 'undefined') {
        url = '/me/videos?direction=asc&sort=alphabetical&per_page=100'
      }
      xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
          let response = JSON.parse(this.responseText)
          videos.push(...response.data)
          let next = response.paging.next
          if (next) {
            loadVideos(next)
          } else {
            loadingVideos.value = false
          }
        }
      });
      xhr.withCredentials = false
      xhr.open("GET", "https://api.vimeo.com" + url);
      xhr.setRequestHeader("Authorization", "Bearer 238dd381dbe83ef092bc07e50e216ed3");

      xhr.send(data)
    }
    loadVideos()
    return { loadingVideos, videos }
  }
})
</script>